export default {
    routes: {
        pagePublicHome: "Home",
        pagePublicContact: "Contact",
        pagePublicLogin: "(en) Iniciar sesión",
        pagePrivateHome: "(en) Inicio Private",
        pagePrivateLogout: "(en) Cerrar sesión",
    },
    errors: {
        auth: {
            login: {
                acces_denied: "You can't  perform that action",
                registration_incomplete: 'You must finish the registration',
                account_not_verified: 'Unverified account',
                unauthenticated: 'Unidentified user',
                item_not_found: 'Item not found',
                invalid_route: 'Invalid route',
                unknown: 'Unknown',
                general_error: 'There has been an error, check the data'
            },
            register: {
                acces_denied: "You can't perform that action",
                registration_incomplete: 'You must finish the registration',
                account_not_verified: 'Unverified account',
                unauthenticated: 'Unidentified user',
                ItemNotFound: 'Item not found',
                InvalidRoute: 'Invalid route',
                Unknown: 'Unknown',
                GeneralError: 'There has been an error, check the data'
            }
        }
    },
};