
export default {
  name: 'ArtworkCard',
  components: {},
  props: {
    artwork: Object
  },
  setup(props) {
    const getImage = (size) => {
      const images = props.artwork?.images;
      const imageHeader = images?.find((image) => image.type === 'header_list');
      return imageHeader?.thumbnails?.find((image) => image.size === size);
    };

    return {
      getImage,
    };
  }
};
