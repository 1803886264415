
import getMethods from "@/api/methods.js";
import { useStore, mapGetters, mapActions } from 'vuex';
import store from '@/store';
import { ref } from 'vue'; 

export default {
  name: 'ArtworkCarousel',
  components: {},
  props: {
    artwork: Object
  },
  setup() {
    const store = useStore();
    const selectedTime = ref('5000');
    const titleChecked = ref(true);
    const avatarChecked = ref(true);
    const qrcodeChecked = ref(true);

    const initCarousel = () => {
        getMethods.showCarousel()
    };

    const showCarousel = () => {
      store.state.carousel_time = selectedTime.value;
      store.state.carousel_title = titleChecked.value;
      store.state.carousel_avatar = avatarChecked.value;
      store.state.carousel_qrcode = qrcodeChecked.value;

      getMethods.showCarousel();
    };

    return {
        initCarousel,
        showCarousel,
        selectedTime,
        titleChecked,
        avatarChecked,
        qrcodeChecked,
    };
  }
};
