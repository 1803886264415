
import { defineComponent, computed, onMounted, ref, capitalize } from 'vue';
import getMethods from "@/api/methods.js";
import accountApi from "@/api/account.js";
import store from '@/store';

export default defineComponent({
  name: 'ArtworkFilter',
  components: {},
//   props: {
//     artwork: Object,
//   },
  data() {
    return {
        loadingArtwork:false,
        buttonArtworkText:'Buscar',
        artists:{},
        filters_bck: {},
        filters: {
                disciplines: {},
                artwork_type: {
                    nft: true,
                    physical: true
                },
                sale_type: {
                    sell: false,
                    auction: false
                },
                artist: '',
                artwork: { name: '' },
                order:'date_desc'
            },
            disciplines: {}
        }
  },
  async mounted() 
  {
    const disciplines = await accountApi.getPublicArtworkDisciplines();
    this.artists = await accountApi.getPublicListArtistsValidated();

    this.disciplines = store.getters.disciplines
    for (const key in this.disciplines) {
      const discipline = this.disciplines[key];
      this.filters.disciplines[discipline.tag] = true;
    }

    this.filters_bck = JSON.parse(JSON.stringify(this.filters));
  },
  setup(props) {

    const per_page = ref(200);
    const page = ref(1);

    return {
        //
    }
  },
  methods: {
    capitalize(value) {
      if (!value) return '';
      return value
        .toLowerCase()
        .replace(/(?:^|\s)\S/g, function(match) {
          return match.toUpperCase();
        });
    },
    initFilter() {
        this.filters = JSON.parse(JSON.stringify(this.filters_bck));
    },
    updateFilter () {

        if(this.loadingArtwork) {
            return;
        }

        this.loadingArtwork = true;
        this.buttonArtworkText = 'Por favor espera...';
        store.state.artworks_processing = false;

        accountApi.getPublicListArtwork(100,1,{'filters':this.filters}).then(() => {
            this.loadingArtwork = false;
            this.buttonArtworkText = 'Buscar';
        });
    }
  }
});
