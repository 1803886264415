import axiosInstance from "./axiosInstance.js";
import { useRouter } from "vue-router";
import store from '@/store';

const getPublicListArtistsValidated = async() => {
    try {
        const response = await axiosInstance.get('/public/artist/list-public-validated');

        if (response.data?.data) {
            return response.data.data.map((element) => {
                return {
                    label: element.full_name,
                    code: element.hash
                };
            });
        }

    } catch (error) {
        if (error.response && error.response.status === 401) {
            console.log('getPublicListArtistsValidated ERROR')
        }
    }
};

const getPublicListArtwork = async (perPage, page, filters) => {
    try {
        store.state.artworks_processing = true;

        const roleHash = store.state.user.data.role[0].hash;
        //const response = await axiosInstance.get("/private/role/"+roleHash+"/artwork/list/"+perPage+"/"+page,{
        const response = await axiosInstance.get("/public/artwork/list/"+perPage+"/"+page,{
            params: filters
        });

        store.state.artworks_processing = false;
        store.state.artworks = response.data.data.data;
        store.state.artworks_total = response.data.data.total;
        
        return response.data.data.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            console.log('getPublicListArtwork: 401 logout')
            store.dispatch('logout');
        }
    }
};

const getPublicArtworkDisciplines = async () => {
    try {
        const roleHash = store.state.user.data.role[0].hash;
        const response = await axiosInstance.get("/public/artwork/disciplines/list");

        store.state.disciplines = response.data.data;
        return response.data.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            console.log('401 logout')
            store.dispatch('logout');
        }
    }
};


export default {
    getPublicListArtistsValidated,
    getPublicListArtwork,
    getPublicArtworkDisciplines,
};