<template>
  <div v-if="isUserLoggedIn" class="body">

    <cp-carousel></cp-carousel>

    <div class="content mb-5" id="navbar">
      <ul class="nav justify-content-end">
          <li class="nav-item">
            <a class="nav-link text-gray-800" v-if="isUserLoggedIn" @click="submitLogout">{{ tr("routes.pagePrivateLogout") }}</a>
          </li>
        </ul>
    </div>

    <div class="container">
        <div class="sidebar">
          <div class="logo" style="background-image: url(media/logo4auth.svg);"></div>
          <i class="far fa-images"></i>
          <i class="fa fa-envelope"></i>

          <div class="show here menu-item py-3 icons">
            <a><font-awesome-icon icon="images" /></a>
          </div>
        </div>
        <router-view></router-view>
      </div>
    </div>

    <div v-if="!isUserLoggedIn">
      <router-view></router-view>
    </div>

</template>

<script>
import router from "@/router";
import store from '@/store';
import { computed } from "vue";
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import cpCarousel from "@/components/Common/Carousel.vue";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export default {
  name: "App",
  components: {
    cpCarousel,
  },
  data() {
    return {
      user: null,
      pagePrivateLogout: '',
    };
  },
  created() 
  {
    const pagePrivateLogout = router.resolve({ name: "PrivateLogout" });
    this.pagePrivateLogout = { path: pagePrivateLogout.path };
    this.user = store.state.user.data;
  },
  setup(_, { root }) 
  {
    const store = useStore();
    const router = useRouter();

    const isUserLoggedIn = computed(() => store.state.authenticated);

    const filteredRoutes = computed(() => {
    return router.options.routes.filter(route => {
      return route.meta && route.meta.requiresAuth ? isUserLoggedIn.value : !isUserLoggedIn.value;
      })
    });

    const submitLogout = async () => {
      try {
        await store.dispatch('logout');
      } catch (error) {
        console.log('submitLogout => '+error);
      }
    };

    return {
      isUserLoggedIn,
      filteredRoutes,
      submitLogout,
    };
  },
  methods: {

  }
};
</script>
<style scoped lang="scss">
.body {
  background-color: #f3f6f9;
  min-height: 100vh;
}
.nav {
  height: 70px;
  background-color: white;
}
.nav .nav-item a {
  margin: 13px;
  cursor:pointer;
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100px;
  background-color: #333;
  color: #fff;
  padding: 20px;
}
.logo {
  width: 59px;
  height: 68px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.icons {
  text-align: center;
}
.icons a {
  font-size: 1.3em;
  color:white;
}
.fa {
  display: block;
  font-size: 24px;
  margin-bottom: 20px;
}
.text-gray-800 {
    color: #3f4254!important;
}
.form-control {
    height: 50px;
}
</style>