
import { useStore } from "vuex";
import { useRouter } from "vue-router";
  
export default {
  name: "UserLogout",

  setup() 
  {
    const router = useRouter();
    const store = useStore();

    const submitLogout = async () => {
      try {
        await store.dispatch('logout');

            router.push({ name: "PublicHome" });

      } catch (error) {
        console.log(error);
      }
    };

    return { 
      submitLogout
    };
  }
};
