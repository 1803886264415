
import { computed } from 'vue';
import { useStore, mapGetters, mapActions } from 'vuex';
import { Carousel, Slide } from 'vue3-carousel';
import { useRouter } from "vue-router";
import { ref } from 'vue';
import QRCode from 'qrcode';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import getMethods from "@/api/methods.js";
import store from '@/store';

import 'vue3-carousel/dist/carousel.css'

export default {
    name: 'cpCarousel',
    components: {
        Carousel,
        Slide,
        VueQrcode,
    },
    computed: {
      ...mapGetters(['show_carousel'])
    },
    data() {
      return {
        carousel_title: false
      }
    },
    props: {
        //showCarousel: Boolean,
        //title: String
    },
    setup() {
      const store = useStore();
      const router = useRouter();

      const artworks = computed(() => {
        return store.getters.artworks;
      });

      const selectedTime = computed(() => {
        return store.getters.carousel_time;
      });

      const titleChecked = computed(() => {
        return store.getters.carousel_title;
      });

      const avatarChecked = computed(() => {
        return store.getters.carousel_avatar;
      });

      const qrcodeChecked = computed(() => {
        return store.getters.carousel_qrcode;
      });

      const getArtistRoute = (artist) => {
        return process.env.VUE_APP_PUBLIC_URL+'/es/artista/'+artist.hash+'/'+artist.slug;
      };

      const getAuctionRoute = (artwork) => {
        return process.env.VUE_APP_PUBLIC_URL+'/es/obra/'+artwork.hash+'/'+artwork.slug;
      };

      return {
        artworks,
        selectedTime,
        titleChecked,
        avatarChecked,
        qrcodeChecked,
        getArtistRoute,
        getAuctionRoute,
        //getAvatarImage,
      };
    },
    mounted() {
      //this.show_carousel = store.state.show_carousel;
    //var myCarousel = document.querySelector('#myCarousel')
       //new Bootstrap.Carousel(myCarousel)

       //this.carousel()
  },
  methods: {
    carousel() {
      getMethods.showCarousel();
    },
    getImage(images) {
        //const images = artworks?.images;
        //console.log(images)
        const imageHeader = images?.find((image) => image.type === 'header_list');
        return imageHeader?.thumbnails?.find((image) => image.size === 'lg');
    }
  }
}
