import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "ms-3 mt-2" }
const _hoisted_5 = {
  key: 0,
  type: "button",
  class: "btn btn-dark btn-sm me-3"
}
const _hoisted_6 = {
  key: 1,
  type: "button",
  class: "btn btn-dark btn-sm me-3"
}
const _hoisted_7 = {
  key: 2,
  type: "button",
  class: "btn btn-dark btn-sm me-3"
}
const _hoisted_8 = {
  key: 3,
  type: "button",
  class: "btn btn-dark btn-sm me-3"
}
const _hoisted_9 = {
  key: 4,
  type: "button",
  class: "btn btn-dark btn-sm me-3"
}
const _hoisted_10 = {
  key: 5,
  type: "button",
  class: "btn btn-dark btn-sm me-3"
}
const _hoisted_11 = {
  key: 6,
  type: "button",
  class: "btn btn-dark btn-sm me-3"
}
const _hoisted_12 = {
  key: 7,
  type: "button",
  class: "btn btn-dark btn-sm me-3"
}
const _hoisted_13 = { class: "card-body" }
const _hoisted_14 = { class: "card-title" }
const _hoisted_15 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: $setup.getImage('lg')?.uri,
      class: "card-img-top"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        ($props.artwork.is_nft)
          ? (_openBlock(), _createElementBlock("button", _hoisted_5, " NFT "))
          : (_openBlock(), _createElementBlock("button", _hoisted_6, " FÍSICA ")),
        ($props.artwork.is_public)
          ? (_openBlock(), _createElementBlock("button", _hoisted_7, " PÚBLICO "))
          : (_openBlock(), _createElementBlock("button", _hoisted_8, " PRIVADO ")),
        ($props.artwork.has_firm)
          ? (_openBlock(), _createElementBlock("button", _hoisted_9, " FIRMADO "))
          : (_openBlock(), _createElementBlock("button", _hoisted_10, " SIN FIRMAR ")),
        ($props.artwork.active_auction)
          ? (_openBlock(), _createElementBlock("button", _hoisted_11, " SUBASTA "))
          : _createCommentVNode("", true),
        ($props.artwork.active_artwork_sell)
          ? (_openBlock(), _createElementBlock("button", _hoisted_12, " VENTA "))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("h5", _hoisted_14, _toDisplayString($props.artwork.title), 1),
      _createElementVNode("p", {
        class: "card-text text",
        innerHTML: $props.artwork.description
      }, null, 8, _hoisted_15)
    ])
  ]))
}