<template>
  <div id="content-home">
    <div class="d-flex flex-wrap flex-stack mb-3">
      <h2 class="fs-5 fw-bold my-2">Listado de obras</h2>
    </div>

    <div class="d-flex flex-column mb-4">
      <artwork-filter></artwork-filter>
    </div>

    <div class="d-flex flex-column mb-4">
      <artwork-carousel></artwork-carousel>
    </div>

    <div class="d-flex flex-column">
      <div v-if="artworks.length > 0" class="row">
        <template v-for="(artwork, index) in artworks" :key="index">
          <div class="col-md-6 col-xl-4 mb-4">
            <artwork-card :artwork="artwork"></artwork-card>
          </div>
        </template>
      </div>
      <div v-else-if="artworks_processing === false && artworks.length === 0">
        <div class="modal-body scroll-y mx-5 pt-0 pb-15 mt-5">
          <div class="text-center mb-13">
            <h2 class="mb-3">No hay resultados</h2>
            <div class="text-muted fw-semibold fs-5">Pruebe con otros filtros.</div>
          </div>
        </div>
      </div>
      <div v-else-if="artworks_processing">
        <div class="modal-body scroll-y mx-5 pt-0 pb-15 mt-5">
          <div class="text-center mb-13">
            <h2 class="mb-3">Cargando datos...</h2>
            <div class="spinner-border text-secondary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import store from '@/store';
import accountApi from "@/api/account.js";

import cpCarousel from "@/components/Common/Carousel.vue";
import ArtworkFilter from "@/components/Artwork/ArtworkFilter.vue";
import ArtworkCard from "@/components/Artwork/ArtworkCard.vue";
import ArtworkCarousel from "@/components/Artwork/ArtworkCarousel.vue";

export default {
  name: "PrivateHome",
  components: {
    ArtworkFilter,
    ArtworkCard,
    ArtworkCarousel,
  },
  data() {
    return {

      user: null
    };
  },
  async mounted() 
  {
    this.user = store.state.user.data;
    this.processing = true;

    //const disciplines = await accountApi.getPublicArtworkDisciplines();
    
    store.state.artworks_processing = true;
    const artworks = await accountApi.getPublicListArtwork(200,1,{'filters':this.filters});
  },
  setup() 
  {
    const store = useStore();

    const per_page = ref(9);
    const page = ref(1);
    const artists = ref();

    const artworks = computed(() => {
      return store.getters.artworks;
    });

    const artworks_processing = computed(() => {
      return store.getters.artworks_processing;
    });

    return {
      per_page,
      page,
      artists,
      artworks,
      artworks_processing,
    };
  },
  methods: {
    //
  }
};

/*
{{baseUrl}}/api/v1/private/artwork/section-data GET
{{baseUrl}}/api/v1/private/artwork/list/{perPage}/{page} GET
{{baseUrl}}/api/v1/private/view/{artworkHash} GET
*/
</script>
