<template>
    <div>
      <h1>Public Home</h1>

      <router-link v-if="pagePublicContact" :to="pagePublicContact.path">{{ tr("routes.pagePublicContact") }}</router-link>
      
    </div>
  </template>
<script>
import router from "@/router";

export default {
  name: "PublicHome",
  data() {
    return {
      pagePublicContact: '',
    };
  },
  created() {
    const pagePublicContact = router.resolve({ name: "PublicContact" });
    this.pagePublicContact = { path: pagePublicContact.path };
  },
};
</script>