import { createI18n } from "vue-i18n";
import en from "./locales/en";
import es from "./locales/es";

const messages = {
  en: en,
  es: es,
};

const numberFormats = {
    en: {
        currency: {
        style: 'currency',
        currency: 'EUR'
        }
    },
    es: {
        currency: {
        style: 'currency',
        currency: 'EUR'
        }
    }
};

const i18n = createI18n({
    legacy: true,
    locale: "es",
    fallbackLocale: "es",
    globalInjection: true,
    numberFormats,
    messages,
});

export default i18n;