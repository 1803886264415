import { createRouter, createWebHistory } from "vue-router";
import store from '@/store';

// Public routes
import PublicHome from "../views/Public/PublicHome.vue";
import PublicContact from "../views/Public/PublicHome.vue";
import PublicLogin from "../components/Auth/UserLogin.vue";

// Private routes
import PrivateLogout from "../components/Auth/UserLogout.vue";
import PrivateHome from "../views/Private/PrivateHome.vue";

const routes = [
  { 
    path: "/",
    // path: "/:locale(en|fr)/home",
    name: "PublicHome",
    component: PublicHome,
    meta: { 
      requiresAuth: false,
      title: '' 
    },
  },
  { 
    path: "/contact",
    name: "PublicContact",
    component: PublicContact,
    meta: { 
      requiresAuth: false,
      title: '' 
    },
  },
  { 
    path: "/login",
    name: "PublicLogin",
    component: PublicLogin,
    meta: { 
      requiresAuth: false,
      title: ''
    }, 
  },
  { 
    path: "/home",
    text: "Inicio Private",
    name: "PrivateHome",
    component: PrivateHome,
    meta: { 
      requiresAuth: true ,
      title: ''
    },
  },
  { 
    path: "/logout", 
    text : "Cerrar sesión",
    name : "PrivateLogout",
    component: PrivateLogout,
    meta: { 
      requiresAuth: true,
      title: ''
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
});

router.redirectTo = (path) => {
  router.push(path)
}

router.beforeEach((to, from, next) => 
{
  if (to.path === '/' && !store.state.authenticated) {
    next({ name: "PublicLogin" });
  }

  if (to.meta.requiresAuth === false) {
      if (store.state.authenticated) {
          next({ name: "PrivateHome" });
      }
      next();
  } else {
      if (store.state.authenticated) {
          next()
      } else {
          next({ name: "PublicLogin" });
      }
  }
});

export default router;