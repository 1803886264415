//import axios from 'axios';
import router from '@/router';
import axiosInstance from "@/api/axiosInstance.js";
import createPersistedState from 'vuex-persistedstate';
import { createStore } from 'vuex';
//import { redirectTo } from '@/store';

const store = createStore({
    plugins:[
        createPersistedState()
    ],
    state:{
        authenticated:false,
        user:{},
        artists:{},
        artworks:{},
        artworks_processing:0,
        artworks_total:0,
        show_carousel:false,
        carousel_title:null,
        carousel_avatar:null,
        carousel_qrcode:null,
        carousel_time:null,
        disciplines: {}
    },
    getters:{
        authenticated(state){
            return state.authenticated;
        },
        user(state){
            return state.user;
        },
        artists(state){
            return state.artworks;
        },
        artworks(state){
            return state.artworks;
        },
        artworks_processing(state){
            return state.artworks_processing;
        },
        disciplines(state){
            return state.disciplines;
        },
        show_carousel(state){
            return state.show_carousel;
        },
        carousel_title(state){
            return state.carousel_title;
        },
        carousel_avatar(state){
            return state.carousel_avatar;
        },
        carousel_qrcode(state){
            return state.carousel_qrcode;
        },
        carousel_time(state){
            return state.carousel_time;
        }
    },
    mutations:{
        SET_AUTHENTICATED (state, value) {
            state.authenticated = value;
        },
        SET_USER (state, value) {
            state.user = value;
        },
    },
    actions:{
        async login({commit}, { email, password }) 
        {
            try 
            {  
                const response_login = await axiosInstance.post("/auth/login", { email, password });
        
                if (response_login.status === 200)
                {
                    const response_me = await axiosInstance.get("/public/profile/me");
                    if (response_me.status === 200)
                    {
                        commit('SET_USER',response_me.data)
                        commit('SET_AUTHENTICATED',true)
        
                        return response_me.status;
                    }
        
                } else {
                    commit('SET_USER',{})
                    commit('SET_AUTHENTICATED',false)
        
                    throw new Error("errors.auth.login.general_error");
                }        
            } catch (error) 
            {
                commit('SET_USER',{})
                commit('SET_AUTHENTICATED',false)
        
                if (error.response && error.response.status === 401) {
                    throw new Error("errors.auth.login.unauthenticated");
                } else {
                    throw new Error("errors.auth.login.general_error");
                }
            }
        },
        async logout({commit}){
            await axiosInstance.get("/auth/logout");
            commit('SET_USER',{});
            commit('SET_AUTHENTICATED',false);
            router.redirectTo('/login');
        },
        getArtworks(state)
        {

            return state.artworks;
        }
    }
});

export default store;