import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from "./i18n";
import translationMixin from "./mixins/translation";

import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faUser, faEnvelope, faFacebook, faTwitter, faInstagram, faImages);

const app = createApp(App)
.use(router)
.use(store)
.use(i18n)
.mixin(translationMixin)
.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app');