import axios from "axios";
//import Cookies from 'js-cookie';

const instance = createInstance(process.env.VUE_APP_API_URL + '/api/v1', {withCredentials: true});

// instance.interceptors.request.use((config) => {
//     const token = Cookies.get('token');
//     if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//     }

//     return config;
// });

// instance.interceptors.response.use(
//     (response) => {
//         // Stockez les cookies ici, si nécessaire
//         return response;
//     },
//     (error) => {
//         // Gérez les erreurs ici, si nécessaire
//         return Promise.reject(error);
//     }
// );

function createInstance(baseURL) {
	const params = {
		baseURL,
		withCredentials: true,
	};
	return axios.create(params);
}

export default instance;