export default {
    routes: {
        pagePublicHome: "Inicio",
        pagePublicContact: "Contacto",
        pagePublicLogin: "Iniciar sesión",
        pagePrivateHome: "Inicio Private",
        pagePrivateLogout: "Cerrar sesión",
    },
    errors: {
        auth: {
            login: {
                acces_denied: "Ha habido un error, revisa los datos",
                registration_incomplete: 'You must finish the registration',
                account_not_verified: 'Unverified account',
                unauthenticated: 'Unidentified user',
                item_not_found: 'Item not found',
                invalid_route: 'Invalid route',
                unknown: 'Unknown',
                general_error: 'There has been an error, check the data'
            },
            register: {
                acces_denied: "You can't perform that action",
                registration_incomplete: 'You must finish the registration',
                account_not_verified: 'Unverified account',
                unauthenticated: 'Unidentified user',
                ItemNotFound: 'Item not found',
                InvalidRoute: 'Invalid route',
                Unknown: 'Unknown',
                GeneralError: 'There has been an error, check the data'
            }
        }
    },
};