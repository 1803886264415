
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "UserLogin",
  data(){
    return {
      auth:{
          email:"",
          password:""
      },
      validationErrors:{}
    }
  },
  setup() 
  {
    const router = useRouter();
    const store = useStore();

    const email = ref();
    const password = ref();
    const errorMessage = ref();
    const processing = ref(false);

    const submitLoginForm = async () => {
      processing.value = true;
      try {
        await store.dispatch('login', { email: email.value, password: password.value });
        router.push({ name: "PrivateHome" });
      } catch (error) {
        console.error('ERROR LOGIN:', error);
        processing.value = false;
        password.value = '';
        errorMessage.value = error;
      }
    };

    return { 
      email, 
      password, 
      errorMessage,
      processing,
      submitLoginForm 
    };
  },
};
