import { useRouter } from "vue-router";
import store from '@/store';


const showCarousel = () => 
{
    const content_home = document.querySelector('#content-home');
    const navbar = document.querySelector('#navbar');
    const sidebar = document.querySelector('.sidebar');
    const body = document.querySelector('.body');

    if(store.state.show_carousel === false) {

        if (document.fullscreenElement) {
            document.exitFullscreen();
        }

        window.scrollTo(0, 0);

        content_home.style.display = 'none';
        navbar.style.display = 'none';
        sidebar.style.display = 'none';
        body.style.backgroundColor = 'black';
        store.state.show_carousel = true;

        const fullScreen = document.documentElement;
        if (fullScreen.requestFullscreen) {
            fullScreen.requestFullscreen();
        } else if (fullScreen.webkitRequestFullscreen) {
           fullScreen.webkitRequestFullscreen();
        }
    } else {
        content_home.style.display = 'block';
        navbar.style.display = 'block';
        sidebar.style.display = 'block';
        body.style.backgroundColor = '#f3f6f9';
        store.state.show_carousel = false;

        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
    }
};


export default {
    showCarousel
};